import React, { useEffect, useState } from 'react';
import { Loader, Toast } from '@getvim/atomic-ui';
import { useIsAppOpenInHub } from '@getvim/components-hooks-vim-connect';
import { ModuleNames } from '@getvim/vim-app-infra';
import { useLoading } from '../../hooks';
import { apiClient } from '../../api';
import { Header } from '../header';
import './index.less';

export const Widget: React.FC = () => {
  const { isLoading } = useLoading();
  const isAppOpen = useIsAppOpenInHub(ModuleNames.MockCanvasAppWidget);
  const [appConfig, setAppConfig] = useState<{
    launchUrl: string | undefined;
    appName: string | undefined;
  }>({ launchUrl: undefined, appName: undefined });

  const { ToastContainer, Slide } = Toast;

  const getLaunchUrl = async () => {
    const data = await apiClient.getConfig();

    setAppConfig({ launchUrl: data?.url, appName: data?.appName });
  };

  useEffect(() => {
    if (isAppOpen) {
      getLaunchUrl();
    }
  }, [isAppOpen]);

  return (
    <>
      {isLoading || !appConfig ? (
        <Loader size="default" type="dots" />
      ) : (
        <div className="generic-app-container">
          <Header appName={appConfig.appName} />
          <div className="frame-wrapper">
            {appConfig.launchUrl && <iframe allow='clipboard-write; microphone;' className="frame" src={appConfig.launchUrl} />}
          </div>
        </div>
      )}
      <ToastContainer
        className=""
        position="top-right"
        hideProgressBar
        autoClose={5000}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
    </>
  );
};
