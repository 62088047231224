import { gql } from '@apollo/client';
import { gqlClient } from '../gql-client';

export const getConfig = async (): Promise<{ url: string; appName: string } | undefined> => {
  try {
    const { data } = await gqlClient.query({
      query: getConfigQuery,
      variables: {},
      fetchPolicy: 'no-cache',
    });

    return {
      url: data.getConfig.url,
      appName: data.getConfig.appName,
    };
  } catch (error) {
    console.log('Failed to fetch query', { error });
    return undefined;
  }
};

const getConfigQuery = gql`
  query GetConfig {
    getConfig {
      url
      appName
    }
  }
`;
