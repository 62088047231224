import { useReducer } from 'react';
import { singletonHook } from 'react-singleton-hook';

interface IAction {
  type: string;
  payload?: any;
}

interface IState {
  isLoading: boolean;
  displayError: boolean;
}

const initialState = {
  isLoading: false,
  displayError: false,
};

export const ACTION_TYPE = {
  SET_IS_LOADING: 'setIsLoading',
  SET_UPLOAD_ERROR: 'setUploadError',
  SET_INITIAL_STATE: 'setInitialState',
};

function reducer(state: IState, action: IAction) {
  switch (action.type) {
    case ACTION_TYPE.SET_IS_LOADING:
      return { ...state, ...action.payload, displayError: false };
    case ACTION_TYPE.SET_UPLOAD_ERROR:
      return { ...initialState, displayError: true };
    default:
      return initialState;
  }
}

let globalDispatch: any;

const useImplementation = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  globalDispatch = dispatch;
  return state;
};

export const dispatch = (action: IAction) => globalDispatch(action);
export const useLoading = singletonHook(initialState, useImplementation);
