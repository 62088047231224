import { useMemo, useCallback } from 'react';
import axios from 'axios';
import { getAccessToken, ModuleNames } from '@getvim/vim-app-infra';
import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';

export const useApi = () => {
  const apiUrl = VimConnectGqlClient.getHttpLinkUrl(
    '/mock-canvas-app/api',
    ModuleNames.MockCanvasAppWidget,
  );

  const axiosRequest = useCallback(
    async (method, uri, data?, headers?) => {
      try {
        const params = method === 'GET' ? data : null;
        const body = method !== 'GET' ? data : null;
        return axios(`${apiUrl}/${uri}`, {
          method,
          params,
          data: body,
          headers: { ...headers, Authorization: getAccessToken() },
        }).catch((e) => {
          /** throw error response context */
          if (e.response.data) {
            throw e.response.data;
          }
          throw e;
        });
      } catch (e) {
        throw e;
      }
    },
    [apiUrl],
  );

  return useMemo(
    () => ({
      uploadAppLogo: (params) => {
        return axiosRequest('POST', 'upload-logo', params, {
          'Content-Type': 'multipart/form-data',
        }).then(({ data }) => data);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosRequest],
  );
};
