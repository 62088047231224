import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';
import { getAccessToken, ModuleNames } from '@getvim/vim-app-infra';

const apiUrl = VimConnectGqlClient.getHttpLinkUrl(
  '/mock-canvas-app/api/graphql',
  ModuleNames.MockCanvasAppWidget,
);
export const gqlClient = new VimConnectGqlClient({
  apiUrl,
  accessTokenCallback: getAccessToken,
});
