import React, { useRef, FC, ChangeEvent } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Toast } from '@getvim/atomic-ui';

import { dispatch, ACTION_TYPE, useApi } from '../../hooks';
import './styles.less';

interface HeaderProps {
  appName: string | undefined;
}

export const Header: FC<HeaderProps> = ({ appName }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { createToast, ToastTypes } = Toast;
  const { uploadAppLogo } = useApi();
  const triggerLoading = (value: boolean) => {
    dispatch({
      type: ACTION_TYPE.SET_IS_LOADING,
      payload: {
        isLoading: value,
      },
    });
  };

  const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { validity, files },
    } = event;

    if (validity.valid && files?.[0]) {
      try {
        const formData = new FormData();
        formData.append('file', files[0]);

        triggerLoading(true);
        await uploadAppLogo(formData);

        createToast({
          title: 'Success!',
          message:
            'The app logo has been successfully uploaded.\n Please refresh the page to apply the changes',
          type: ToastTypes.SUCCESS,
          html: true,
        });
      } catch (error) {
        dispatch({
          type: ACTION_TYPE.SET_UPLOAD_ERROR,
        });
        createToast({
          title: 'Oops',
          message: 'Something went wrong',
          type: ToastTypes.ERROR,
          html: true,
        });
      } finally {
        triggerLoading(false);

        hiddenFileInput!.current!.value = '';
      }
    }
  };

  const handleClick = () => hiddenFileInput.current?.click();

  return (
    <div className="app-header">
      <span className="app-header__title">{appName}</span>
      <input
        type="file"
        id="csv-file"
        accept=".svg"
        onChange={handleUploadFile}
        style={{ display: 'none' }}
        multiple={false}
        ref={hiddenFileInput}
      />
      <Dropdown drop="down" alignRight={true}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <i className="icon-menu-bars" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={handleClick}>
            <span>Upload Logo</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
